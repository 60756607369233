<template>
  <div>
    <header>
      <img src="@/assets/logo.svg" alt="manycontent" class="w-42"/>
      <div class="hero">
        <h1>
          Recebemos o seu pedido de assinatura.
        </h1>
        <h2 v-if="isBoleto">
          Estamos <b>processando o seu pedido</b>, enviaremos uma mensagem no seu email de cadastro <span v-if="checkoutFormData.email">{{checkoutFormData.email}}</span> com as informações para pagamento do boleto.
        </h2>
        <h2 v-else>
          Estamos <b>processando o seu pedido</b>, enviaremos uma mensagem no seu email de cadastro <span v-if="checkoutFormData.email">{{checkoutFormData.email}}</span> assim que sua assinatura estiver concluída.
        </h2>
        <p></p>
      </div>
    </header>

    <div class="px-4 w-full md:w-10/12 mx-auto py-8 flex flex-col md:flex-row justify-between items-start space-y-12 md:space-y-0 md:space-x-20">
      <div class="md:w-2/3">
        <div v-if="isBoleto" class="flex flex-col items-center gap-4">
          <div>
            Estamos <b>processando o seu pedido</b>, enviaremos uma mensagem no seu email de cadastro <span v-if="checkoutFormData.email">{{checkoutFormData.email}}</span> com as informações para pagamento do boleto.
          </div>
          <div>
            Assim que identificarmos o pagamento nós enviaremos para o mesmo email os dados de acesso.
          </div>
          <div class="w-full py-8"><hr></div>
        </div>

        <p class="font-bold text-lg mb-2 text-center lg:text-left">
          Próximo passo - Como acessar a Manycontent e receber seus primeiro conteúdos.
        </p>
        <p class="text-sm md:text-base text-center lg:text-left">
          Assista ao vídeo com o passo a passo sobre como acessar a manycontent e configurar o seu primeiro perfil.
        </p>

        <div class="py-6 lg:w-10/12 mx-auto">
          <div
            class="relative bg-cover bg-no-repeat bg-center"
            style="padding-top: 56.25%"
            :style="`background-image: url('')`"
          >
            <iframe
              class="absolute inset-0 w-full h-full"
              :src="`https://www.youtube.com/embed/DB3rw7xm7Y8`"
              frameborder="0"
              :allowtransparency="true"
            >
            </iframe>
          </div>
        </div>

        <p class="hidden md:block text-center lg:text-left">
          * Se precisar de ajuda sinta-se à vontade para entrar em contato com nossa <a href="http://suporte.manycontent.com/" target="_blank" rel="noopener noreferrer">Equipe de suporte</a>.
        </p>
      </div>
      <div class="md:w-1/3">
        <div class="flex flex-col justify-center space-y-8 lg:w-full">
          <div class="space-y-8 mb-8" v-if="isCheckoutDataLoaded">
            <p class="font-semibold">
              Resumo da compra
            </p>
            <div>
              <p class="flex justify-between">Solicitante: <span>{{checkoutFormData.name}}</span></p>
              <p class="flex justify-between">Email: <span>{{checkoutFormData.email}}</span></p>
              <p class="flex justify-between">telefone: <span>{{checkoutFormData.phone}}</span></p>
            </div>
            <purchase-summary />
            <hr>
          </div>
        </div>
        <div class="space-y-2 lg:w-full m-auto" v-if="!isBoleto">
          <p class="font-semibold text-base md:text-lg text-center lg:text-left">
            Você receberá <b>um email</b> com a sua senha de acesso.
          </p>
          <p class="text-sm md:text-base text-center lg:text-left">
            Se o email com a senha não chegar em alguns instantes você poderá solicitar um outro email logo abaixo.
          </p>
        </div>
        <form
          v-if="!isBoleto"
          class="my-8 flex flex-col m-auto justify-center items-center space-y-4 lg:w-full"
          @submit.prevent="attemptRecoverPassword"
        >
          <base-input
            :label="$t('form.label.email')"
            name="email"
            type="email"
            v-model="form.email"
            autocomplete="username"
            left-icon="far fa-envelope"
          />
          
          <div class="lg:w-full">
            <button
              type="submit"
              class="btn btn-primary whitespace-nowrap"
            >
              Reenviar minha senha
            </button>
          </div>
        </form>
        <small class="block md:hidden mt-8 text-center lg:text-left">
          * Se precisar de ajuda sinta-se à vontade para entrar em contato com nossa <a href="http://suporte.manycontent.com/" target="_blank" rel="noopener noreferrer">Equipe de suporte</a>.
        </small>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import BaseInput from '@/components/base/BaseInput.vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useAuth } from '@/composables/firebase/useAuth'
import { computed, defineComponent, onMounted, reactive, toRaw, watchEffect } from 'vue'
import PurchaseSummary from '@/components/pages/checkout/PurchaseSummary.vue'
import { fbPixel } from '@/plugins/facebook/pixel'

export default defineComponent({
  components: { BaseInput, PurchaseSummary },
  setup () {
    const { sendPasswordResetEmail } = useAuth()

    const {
      isLoaded: isCheckoutDataLoaded,
      products,
      checkoutFormData,
      submitCheckoutStatus,
    } = useCheckout()
    
    const form = reactive({
      email: ''
    })

    const isBoleto = computed(() => {
      const paymentMethod = submitCheckoutStatus.value.data?.paymentMethod
      return paymentMethod === 'boleto'
    })

    const attemptRecoverPassword = () => {
      const { email } = toRaw(form)
      sendPasswordResetEmail(email)
    }
    
    watchEffect(() => {
      if (isCheckoutDataLoaded.value) {
        products.value.map((product: {id: string, name: string, price: number}) => {
          const payload = {
            content_type: 'product',
            content_name: product.name,
            content_ids: [product.id],
            value: product.price,
            currency: 'BRL'
          }
          fbPixel('track', 'Purchase', payload)
          fbPixel('trackSingleCustom','796704134043684', 'COMPROUMANY')
          fbPixel('trackSingleCustom','768749882041585', 'COMPROUMANY2')
        })
      }
    })

    onMounted(() => {
      fbPixel('track', 'PageView')
    })

    return {
      attemptRecoverPassword,
      form,
      isCheckoutDataLoaded,
      products,
      checkoutFormData,
      isBoleto
    }
  }
})
</script>

<style scoped>
@layer components {
  header {
    @apply p-4;
    @apply bg-gradient-to-br from-blue-500 to-green-500;

    .hero {
      @apply flex flex-col justify-center items-center space-y-8 p-8 mx-auto md:w-8/12;
    
      * {
        @apply text-white text-center;
      }

      h1 {
        @apply text-3xl md:text-3xl font-sans font-bold;
      }

      h2 {
        @apply text-xl md:text-2xl font-sans !important;
      }
    }
  }
}
</style>